<template>
  <div class="privacy">
    <v-row dense>
      <v-col align="center">
        <h1>Privacy Policy</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>This Privacy Policy&nbsp;will be effective from April 1, 2022.</p>
        <p>&nbsp;</p>
        <p><strong>Introduction</strong></p>
        <p>Parlay Poker&trade; develops and publishes entertainment games for the web and mobile devices (the &ldquo;Service&rdquo;). In this Privacy Policy, &ldquo;Parlay Poker&rdquo; refers to Neesan Labs LLC and its &ldquo;Affiliates&rdquo;, which shall mean subsidiaries, parent companies, joint ventures, and other corporate entities under common ownership. We may also refer to Parlay Poker as &quot;we&quot; or &quot;us&quot;.</p>
        <p>Parlay Poker is committed to protecting the privacy of its users and provide them a better social gaming experience (&quot;users&quot; or &quot;you&quot;). This Privacy Policy has been created to inform you about how Parlay Poker manages, collects, stores, shares, uses, and protects the information you provide in connection with any Parlay Poker game or application whenever you play our games or otherwise access any of our Services, products, content, Parlaypoker.com and/or the other domains provided by Neesan Labs LLC.&nbsp;</p>
        <p>This Privacy Policy applies whenever you play our games or otherwise access any of our other products, services, content, parlaypoker.com and/or the other domains provided by Parlay Poker, together referred to as &ldquo;Services.&rdquo; This Privacy Policy describes:</p>
        <p>1. &nbsp; &nbsp; what information we collect, how we collect it</p>
        <p>2. &nbsp; &nbsp; how we use that information and with whom we share it</p>
        <p>3. &nbsp; &nbsp; how you can access and update that information and means of communications</p>
        <p>4. &nbsp; &nbsp; how do we protect children privacy</p>
        <p>5. &nbsp; &nbsp; how we protect the information we store and share about you</p>
        <p>By using the Service, you agree to the collection, disclosure, storage and use of your information as outlined in this Privacy Policy. If you do not want Parlay Poker to collect, store, use or share your information in the ways described in this Privacy Policy, you should not play Parlay Poker games or use our Services. &nbsp;Parlay Poker may amend the Privacy Policy from time to time, and Parlay Poker encourages you to consult the Privacy Policy regularly for changes.</p>
        <p>We do not share personal information with third parties for their direct marketing purposes unless you affirmatively agree to such disclosure, typically by &quot;opting in&quot; to receive information from a third party that is participating in a sweepstakes or other promotion on one of our sites. If you do ask us to share your personal information with a third party for its marketing purposes, we will only share information in connection with that specific promotion, as we do not share information with any third party (other than our service providers) on a continual basis.</p>
        <p>&nbsp;To prevent disclosure of your personal information for use in direct marketing by a third party, do not opt into such use when you provide personal information on one of our sites.</p>
        <p>&nbsp;Users in certain jurisdictions have a right to access personal information held about themselves. Your right of access can be exercised in accordance with applicable law.</p>
        <p><strong>1. &nbsp; &nbsp;Information We Collect and How We Collect It</strong></p>
        <p>Parlay Poker only collects Personal Information to the extent necessary to fulfil one or more of its functions and activities and will do so only by fair and reasonable means. Generally, we will collect Personal Information directly from the individual to whom the Personal Information relates, but in some circumstances (including as outlined below), it may be necessary for us to collect information from a third-party source.</p>
        <p><strong>1.a &nbsp; &nbsp; Information About You That We Get from Third-Party Applications, Including Social Networks (like Facebook)</strong></p>
        <p>If you play Parlay Poker games or access any of our other Services on connected third-party applications or connect our Services to any third-party applications, including social networks like Facebook, Parlay Poker may receive certain information about you from the provider of the third-party application. The information we receive depends on the Parlay Poker game you&rsquo;re playing, the third-party application, your privacy settings and, if applicable, your friends&rsquo; privacy settings on that third-party application.</p>
        <p>For example, Parlay Poker may collect and store some or all the following information shared by the provider of the connected third-party application:</p>
        <p>your first and last name;</p>
        <p>your profile picture or its URL;</p>
        <p>your user ID number (like your Facebook ID number), which may be linked to publicly available information like your name and profile photo;</p>
        <p>the user ID number and other public data of your friends;</p>
        <p>the login e-mail you provided to that third-party application when you registered with it;</p>
        <p>your physical location and that of the devices you use to access our Services;</p>
        <p>your gender;</p>
        <p>your birthday and/or age range;</p>
        <p>information about your activities on or through the connected third-party application;</p>
        <p>other publicly available information on the third-party application; and/or</p>
        <p>any other information that you or the provider of the third-party application share with Neesan Labs LLC.&nbsp;</p>
        <p>If you access our Services from a third-party application or connect our Services to a third-party application, you should also read that third-party application&rsquo;s Terms of Service and Privacy Policy.</p>
        <p>If you are unclear about what information a third-party application is sharing with us, please go to the third-party application to find out more about their privacy practices.</p>
        <p>&nbsp;</p>
        <p><strong>1.b &nbsp; &nbsp; Cookies and Automated Information Collection</strong></p>
        <p>Like many websites, Parlay Poker and service providers acting on our behalf, like Google Analytics, store log files and use tracking technologies such as &quot;cookies&quot; to collect information. A cookie is a small data file that is transferred to your computer&rsquo;s hard disk or your mobile device for record-keeping purposes. The Service may send cookies to a computer or a mobile device when a user accesses or views a Service. Information contained in a cookie may be linked to personal information for purposes such as improving the quality of Parlay Poker Service, tailoring recommendations to interests, and making the Service easier to use.</p>
        <p>Third party advertisers on the Services may also use, place, or read cookies or other technologies as described below on your browser, and those practices are subject to their own policies. Cookies can be disabled at any time by changing your web browser&rsquo;s options to stop accepting cookies, to prompt you before accepting a cookie from the websites you visit or limit the type of cookies you allow. Flash cookies operate differently than browser cookies and cookie management tools available in a web browser may not remove flash cookies. If you do not accept cookies, however, you may not be able to use all features, portions, or functionalities of the Service.</p>
        <p>We and our service providers may also employ other technologies including (i) web beacons, which allow us to know if a certain page was visited or whether an e-mail was opened; (ii) tracking pixels, which allow us or our advertising partners to advertise more efficiently and effectively by excluding our current users from certain promotional messages, identifying the source of a new installation or delivering ads to you on other websites; and (iii) local shared objects also known as flash cookies, which help us to reduce fraud, remember your in-game preferences and speed up load times.</p>
        <p>We and our service providers use these log files, tags, and tracking technologies to collect and analyze certain kinds of technical information, including:</p>
        <p>the type of device you are using;</p>
        <p>your operating system version;</p>
        <p>your mobile device&rsquo;s identifiers, like your MAC Address, Identifier For Advertising (IDFA), and/or International Mobile Equipment Identity (IMEI);</p>
        <p>your browser types;</p>
        <p>your browser language;</p>
        <p>referring and exit pages, and URLs;</p>
        <p>platform type;</p>
        <p>the number of clicks on a page or feature;</p>
        <p>domain names;</p>
        <p>landing pages;</p>
        <p>pages viewed and the order of those pages;</p>
        <p>the amount of time spent on particular pages; and</p>
        <p>game state and the date and time of activity on our websites or games.</p>
        <p>In some cases, we will connect this information with your social network ID or Parlay Poker user ID.</p>
        <p><strong>1.c &nbsp; &nbsp;Information About You That You Share With Us Directly</strong></p>
        <p>When you use our Services (whether through a social network or through Parlay Poker directly), you may give us information directly (like when you&rsquo;re setting up your account) and we will store that on our systems and use it for the purposes described in this Privacy Policy.</p>
        <p>Some games or parts of our Services may use a more traditional registration or account set-up process where you may be asked to give us some or all the following information:</p>
        <p>your age or birthday;</p>
        <p>your first and last names;</p>
        <p>your e-mail address;</p>
        <p>a password;</p>
        <p>other information that helps us make sure it&rsquo;s you that is accessing your account or helps us improve our services (such as answers to an online survey or a feedback form).</p>
        <p>We may also let you create a player profile, separate from your social networking site profile (for example, your Facebook profile), that other Parlay Poker players can see. Your player profile may include information like:</p>
        <p>a profile photo;</p>
        <p>game username(s);</p>
        <p>your gender;</p>
        <p>biographic details (like your age or age range);</p>
        <p>approximate location information that you provide;</p>
        <p>links to your profiles on various social network; and</p>
        <p>details about the games you play;</p>
        <p><strong>&nbsp;</strong></p>
        <p><strong>1.d &nbsp; &nbsp;Information You Generate Using Our Communications Features</strong></p>
        <p>Except for games designed for children under 21, you may be able to take part in certain activities on our Service that let you communicate or share information not just with Parlay Poker, but also with other Parlay Poker players. These include:</p>
        <p>participating in player forums and message boards;</p>
        <p>posting public comments to other players&rsquo; profiles or gameboards;</p>
        <p>sending private messages or invitations to other players, either directly on our websites or to their e-mail accounts;</p>
        <p>chatting with other players; and/or</p>
        <p>posting photos or drawings.</p>
        <p>You acknowledge and expressly agree that we may access in real-time, record and/or store archives of these communications, comments, photos and drawings on Parlay Poker servers to make use of them to protect the safety and well-being of our players and Parlay Poker rights and property in connection with our Services; to conduct research; to operate, improve, personalize and optimize our Services and our players&rsquo; experiences, including through the use of analytics; and to manage and deliver contextual advertising.</p>
        <p><strong>1.e &nbsp; &nbsp; Payment Information</strong></p>
        <p>Parlay Poker may obtain the billing and payment information that you provide when your purchase is processed by someone else (like Facebook, Apple, or Google) such as when you purchase something in a game you play on a social network or that you downloaded on your mobile device. Our Terms of Service explain our policies and terms relevant to our charges, billing practices, third-party credits, and virtual currencies.</p>
        <p>If you purchase virtual currency or virtual items in a Parlay Poker game, our third-party payment processor will collect the billing and financial information it needs to process your charges. This may include your postal address, e-mail address and financial information.</p>
        <p>Parlay Poker does not collect or store your financial information, like credit card numbers. However, Parlay Poker payment processors may share non-financial information with us related to your purchases, like your name, billing address and the items purchased to fulfill your order.</p>
        <p><strong>1.f &nbsp; &nbsp; Message Boards and Chat Rooms</strong></p>
        <p>You may be able to take part in certain activities on our websites or games that give you the opportunity to communicate or share information not just with Parlay Poker, but also with other users of our Service. Note that certain information you post and communicate through a Service, or a third-party site or service is public information for which you have no expectation of privacy.</p>
        <p>You may choose to disclose information about yourself in the course of contributing user generated content to &nbsp;Parlay Poker sites or Services or in its chat rooms, blogs, message boards, user &ldquo;profiles&rdquo; for public view or in similar forums on its sites through mobile services and/or through third party sites or services, posting public comments to other users&apos; profiles or gameboards, sending private messages or invitations to other users, either directly on our websites or to their e-mail accounts and posting photos. Information that you disclose in any of these forums is public information, and there is no expectation of privacy or confidentiality.</p>
        <p>You should be aware that any personally identifiable information you submit during these public activities can be read, collected, or used by other users of these forums, and could be used to send you unsolicited messages. &nbsp;Parlay Poker is not responsible for the personally identifiable information you choose to make public in any of these forums. We may also record and store archives of these communications on Parlay Poker servers to protect the safety and wellbeing of our users and Parlay Poker rights and property in connection with the Service. You acknowledge and consent to the recording and storage of such communications for these purposes.</p>
        <p>If you post a video, image, or photo through a Service for public view, you should be aware that these may be viewed, collected, copied and/or used by other users without your consent. &nbsp;Parlay Poker is not responsible for the videos, images, or photos that you choose to post through a Service. Please see Parlay Poker Terms of Service on this point and for other guidelines about posting content through any Service.</p>
        <p><strong>1.g &nbsp; &nbsp; Customer Support Correspondence</strong></p>
        <p>Except as provided herein, when you ask for help from our Customer Support&nbsp;team, we will collect and store the contact information you give them (generally, your name and e-mail address), information about your game play or activity on our Services, and your Parlay Poker player and/or social network ID number. We will also store the communications you have with the Customer Service team and any information in those communications to provide support and improve the Services. For users of our games designed for children under 21, we will use your contact information for the sole purpose of providing a one-time response to your question. We will then delete your information from our servers.</p>
        <p><strong>1.h &nbsp; &nbsp; Other Sources</strong></p>
        <p>We may collect or receive information about you from other sources like third-party information providers. We use this information along with information you provide us directly, for example, to help you and your friends connect or to serve you advertising more tailored to your interests.</p>
        <p>If you would like to learn more about how we secure your information, please see&nbsp;Security of Your Information section below.<br><br><strong>2. &nbsp; &nbsp;How We Use the Information We Collect and With Whom We Share It</strong></p>
        <p><strong>2.a &nbsp; &nbsp; How We Use Your Information</strong></p>
        <p>The main use of the information we collect, and store is to provide a better gaming experience, but there are other uses as well. The uses for which we collect and store your information include:</p>
        <p>to create your game accounts and allow you to play our games;</p>
        <p>to operate, improve and optimize our Services and our players&rsquo; experiences;</p>
        <p>to identify and suggest connections with other Parlay Poker players and personalize our Services to you;</p>
        <p>to enable players to communicate with each other;</p>
        <p>to provide technical support and respond to player inquiries;</p>
        <p>to protect the safety and well-being of our players;</p>
        <p>to protect Parlay Poker rights and property in connection with our Services;</p>
        <p>to prevent fraud or potentially illegal activities, and to enforce our Terms of Service;</p>
        <p>to manage and deliver contextual and behavioral advertising;</p>
        <p>to notify players of in-game updates, new products or promotional offers;</p>
        <p>to administer rewards, surveys, sweepstakes, contests, or other promotional activities or events sponsored or managed by us or our business partners;</p>
        <p>to comply with our legal obligations, resolve any disputes we may have with you or other players, and to enforce our agreements with third parties; and</p>
        <p>to conduct research.</p>
        <p>One important use of your information is communication. If you have provided your e-mail address to Parlay Poker, we will use it to respond to customer support inquiries, and keep you informed of your in-game activity, including comments from friends, let you know about in-game status and promotions as well as tell you about gift and neighbor requests. Some messages, like invites for friends to join you in a game, may include your name and profile photo. We may also send promotional e-mail messages and promotional SMS messages (e.g. text messages) (&quot;Promotional Communications&quot;) directly or in partnership with other parties, in accordance with your marketing preferences. Each Promotional Communication will offer you choices about receiving additional messages. Receipt of such communications depends on Parlay Poker having certain contact information from you. If you play one of our games designed for children under 13, you will not be able to provide us with certain contact information. Please see the section on Our Policies Concerning Children for more information.<br><br><br></p>
        <p><strong>2.b &nbsp; &nbsp; How We Share Your Information</strong></p>
        <p>We may disclose or publish aggregated information (information about you and other players collectively that is not intended to specifically identify you, for example, players between the ages of 25 and 30 who live Chicago) and other non-personal information about our players for industry analysis, demographic profiling, marketing, analytics, advertising, and other business purposes.<br>In addition, we may share your information (which may include personal information) with third parties (in other words, parties other than Parlay Poker) or allow third parties to collect this information from our Services in the following circumstances:</p>
        <p><strong>2.c &nbsp; &nbsp; Your Consent</strong></p>
        <p>With your consent, we may share your information with third parties or allow them to collect your information from our Services in some ways not specifically described in this Privacy Policy.</p>
        <p><strong>2.d &nbsp; &nbsp; Aggregated and Analytical Information</strong></p>
        <p>We use anonymous, statistical, or aggregated information and may share it with our partners for legitimate business purposes. It has no effect on your privacy, because there is no reasonable way to extract data from the aggregated information that can be associated with you. We will share your identifiable information only subject to the terms of this policy, or subject to your prior consent.</p>
        <p>&nbsp;<strong>2.e &nbsp; &nbsp;Friends and Other Parlay Poker Players</strong></p>
        <p>The Service supports and often encourages you to interact with other players. In most Parlay Poker games, if you play through a social network or register through a social network (for example, Facebook Connect), your social network friends will see your name, profile photo and descriptions of your game activity. Similarly, in many Parlay Poker games, other players, regardless of whether they are your social network friends or not, will be able to see descriptions of your game activity (including the identification of any Parlay Poker games you have played), communicate with you within our Services, and view your game profile, which may include your name or a &ldquo;game name&rdquo; and your profile photo. For example, in Parlay Poker, any other player may see your game profile if you are playing at the same table. In certain cases, your profile photo can reveal your social network ID or Parlay Poker player ID, and access to a social network ID or Parlay Poker player ID may allow others to view the public information associated with your related social network profile or Parlay Poker account. Other players may also be able to send you game requests or even friend requests through the related social network&rsquo;s or Parlay Poker communication channels.</p>
        <p>&nbsp;</p>
        <p><strong>2.f &nbsp; &nbsp;Third-Party Advertising Including Tailored Advertising and Analytics</strong></p>
        <p>We have advertising on our Services so we can continue to offer many of our Services for free.</p>
        <p>We do not actively share personal information with third-party advertisers for their direct marketing purposes unless you give us your consent.</p>
        <p>When advertisers or ad networks place ads in our Services, they may collect, or we may share the following types of information from within our Services:</p>
        <p>performance data (like the number of clicks on an advertisement);</p>
        <p>aggregated and/or de-identified information about you and other players collectively that is not intended to specifically identify you (like players between the ages of 21 and 25 who live San Francisco);</p>
        <p>certain technical information (for example, IP addresses, non-persistent device identifiers such as IDFAs, and de-identified persistent device identifiers such as a hashed Android ID);</p>
        <p>your social network ID;</p>
        <p>and other contextual data about your game play (for example, your level and session length).</p>
        <p>The information collected may be used to:</p>
        <p>measure how effective ads are, to offer you targeted advertising to personalize your experience by showing you advertisements for products and services that are more likely to appeal to you (a practice known as behavioral advertising), and/or</p>
        <p>undertake web analytics (like Google Analytics which is used to analyze traffic and other player activity to improve your experience).</p>
        <p>Advertisers or ad networks may collect this information using tracking technologies like browser cookies and web beacons, and they may use a single tracking technology or multiple tracking technologies at the same time.</p>
        <p>Please note, after clicking on a third-party advertisement, you may no longer be on a site hosted by Parlay Poker or the social network through which you are playing Parlay Poker games.</p>
        <p>If you do not want to receive tailored in-application advertisements from third parties, please see&nbsp;How to Access, Update and Manage Your Information. Please note that no tailored advertisements will be delivered for Parlay Poker games designed for children under 13, though Parlay Poker may allow contextual advertisements to be displayed.</p>
        <p><strong>2.g &nbsp; &nbsp; Third-Party Service Providers</strong></p>
        <p>We will share your information with third-party companies to perform services on our behalf, like processing payments, analyzing data, optimizing game play, e-mail delivery, hosting services, customer service and to help us in our marketing efforts, including managing and delivering contextual and tailored advertisements. We contractually require these third-party service providers to maintain the confidentiality of the information we share with them, and we contractually require them not use your information for anything other than to provide services on our behalf.</p>
        <p><strong>2.h &nbsp; &nbsp; Sale or Merger</strong></p>
        <p>If Parlay Poker undergoes a business transition (including proposed transactions), like a merger, acquisition by another company, or sale of all or part its assets (like selling a game), we may disclose or transfer all your information, including personal information, to the successor organization in the transition. We will make reasonable efforts to let you and other players know (in the way described in&nbsp;Changes to Our Privacy Policy) of the business transition before transferring your personal information.<br><br>&nbsp;</p>
        <p><strong>3. &nbsp; &nbsp;How to Access, Update and Manage Your Information and Means of Communication</strong></p>
        <p><strong>3.a &nbsp; &nbsp; Accessing and Updating Your Information Held by Parlay Poker</strong></p>
        <p>If you want to review, delete, or change the information Parlay Poker has about you or have additional questions, contact us at GDPR Information center, and we will respond to your request within thirty days. For exercising your GDPR rights you can open a request in the Parlay&nbsp;Poker Privacy Portal</p>
        <p><strong>3.b &nbsp; &nbsp; Stopping Use of Your Information</strong></p>
        <p>If you no longer want Parlay Poker to make active use of your information, you may send an e-mail to Privacy@parlaypoker.com Place &quot;Delete My Account&quot; in the subject line and include your first name, last name, e-mail address and your social network ID for the social network from which you access our Services (if applicable) in the body of the e-mail (for example, your Facebook user ID). We will respond to your request within thirty days. Please note that certain records, for example those relating to payments or customer service matters, will be held for legal and accounting purposes. If you have sent content through or posted content on the Service, we may not be able to delete it.</p>
        <p><strong>3.c &nbsp; &nbsp; Manage Information We get from a Third-Party Application</strong></p>
        <p>To manage the information Parlay Poker receives about you from a third-party application where you play our games, like Facebook, you will need to follow the instructions for the third-party application for updating your information and changing your privacy settings.&nbsp;&nbsp;To review and update information associated with your Parlay Poker game profile in certain games, visit the &ldquo;settings&rdquo; page in that game. You also can manage some aspects of information collection and use by visiting the &ldquo;settings&rdquo; page of your mobile device and reviewing the permissions of each application or &ldquo;app.&rdquo;</p>
        <p>Once Parlay Poker receives your information from a third-party application or your mobile device, that information is stored and used by Parlay Poker in accordance with this Privacy Policy. You may access and update that information as described below.</p>
        <p><strong>3.d &nbsp; &nbsp; Opting Out of Geolocation</strong></p>
        <p>If you have previously allowed us to access your geolocation data, you can stop making geolocation available to us by visiting your mobile device&apos;s settings for the relevant application or the &ldquo;settings&rdquo; page for the relevant game.</p>
        <p><strong>3.e &nbsp; &nbsp; Opting Out of Promotional Emails from Parlay Poker</strong></p>
        <p>If you want to stop receiving promotional e-mails from Parlay Poker, click on the &quot;unsubscribe&quot; link in any promotional email from Parlay Poker or visit the e-mail preference. Please note that once we receive your request, it may take an additional period for your opt-out to become effective. Your unsubscribe or e-mail preference change will be processed promptly, and in no event longer than ten business days.</p>
        <p><strong>3.f &nbsp; &nbsp; Opting from FB messages</strong></p>
        <p>If you want to stop receiving messages through our game pages, you may click on the &ldquo;settings&rdquo; icon on the top of such message, and by clicking&rdquo; manage messages&rdquo; you may turn off all messages from such game.</p>
        <p><strong>3.g &nbsp; &nbsp; Opting Out of Other Communications</strong></p>
        <p>When you install our apps on your mobile device you can choose to receive push notifications, which are messages an app sends you on your mobile device even when the app is not on. You can turn off notifications by visiting your mobile device&rsquo;s &ldquo;settings&rdquo; page.</p>
        <p>In most of our games, you may find under &ldquo;your account&rdquo; or Settings&rdquo; section an option to stop e-mail or other notifications.</p>
        <p><strong>3.h &nbsp; &nbsp; Opting Out of Third-Party Tailored Advertising</strong></p>
        <p>If you are interested in more information about tailored advertising and your choices to prevent third parties from delivering tailored web and mobile web advertising, you may visit the following websites:</p>
        <p><a href="http://www.networkadvertising.org/choices/">Network Advertising Initiative Consumer Opt-Out Page</a> or the <a href="http://www.aboutads.info/choices/">Digital Advertising Alliance Opt-Out Page for U.S.-based advertising</a>; and</p>
        <p><a href="http://www.youronlinechoices.com/uk/your-ad-choices">Your Online Choices UK website for EU-based advertising</a>.</p>
        <p>These opt-out tools are provided by third parties, not Parlay Poker. &nbsp;Parlay Poker does not control or operate these tools or the choices that advertisers and others provide through these tools.</p>
        <p>In addition, if you do not want to receive tailored in-application advertisements from third parties that relate to your interests in apps on your mobile device, you may opt- out by adjusting the ad tracking settings on your device. You can also reset the &ldquo;Advertising Identifier&rdquo; (like an IDFA) from your mobile device&rsquo;s settings page, which will prevent continued use of existing behavioral data tied to the previous &ldquo;Advertising Identifier.&rdquo; Further, depending on the platform provider (such as Apple or Google), you may be able to download apps, such as the Digital Advertising Alliance&rsquo;s &ldquo;AppChoices&rdquo; app, that offer to provide you with control regarding the collection and use of cross-app data for tailored advertising. Like the opt-out tools mentioned above for web, these mobile opt-out tools are provided by third parties, not Neesan Labs LLC. &nbsp;Parlay Poker does not control or operate these tools or the choices that advertisers and others provide through these tools.</p>
        <p><strong><em>Please note that you may still receive advertisements from third parties within Parlay Poker games even if you opt out of tailored advertising, but they will not be based on your activity across unrelated web sites or apps.</em></strong></p>
        <p><strong>3.i &nbsp; &nbsp; Opting Out of Cookie Tracking</strong></p>
        <p>You can set your web browser to warn you about attempts to place cookies on your computer or limit the type of cookies you allow. Flash cookies operate differently than browser cookies and cookie management tools available in a web browser may not remove flash cookies. To learn more about and manage flash cookies you can visit the <a href="http://www.adobe.com/">Adobe website</a> and make changes at the <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager02.html">Global Privacy Settings Panel</a>.</p>
        <p>If you disable cookies, you may lose some of the features and functionality of our Services because Parlay Poker cookies are necessary to track and enhance your game activities.</p>
        <p><strong>3.j California Online Privacy Protection Act Notice</strong></p>
        <p>At this time, there is no general agreement on how companies like Parlay Poker should interpret Do Not Track signals. &nbsp;Parlay Poker does not currently respond to Do Not Track signals, whether that signal is received on a computer or on a mobile device. You can learn more about Do Not Track.</p>
        <p>&nbsp;</p>
        <p><strong>4. &nbsp; &nbsp;Children Policy</strong></p>
        <p><strong>4.a &nbsp; &nbsp; Policy Regarding Persons Under 21</strong></p>
        <p>Unless written otherwise, our games are intended for use by those 21 or older for amusement purposes only. Without derogating from the foregoing, Parlay Poker does not knowingly collect personal information from children under the age of 18. Children under the age of 18 should not use our websites or games at any time. If Parlay Poker learns that a person under the age of 18 has provided it with personal information, Parlay Poker will take reasonable steps to delete such information as soon as possible.</p>
        <p>&nbsp;</p>
        <p><strong>5. &nbsp; &nbsp;How we protect the information we store and share about you.</strong></p>
        <p><strong>5.a &nbsp; &nbsp; Security of Your Information</strong></p>
        <p>Parlay Poker has made reasonable technical and organizational measures designed to secure your information both online and offline from accidental loss and from unauthorized access, use, alteration, or disclosure, and we are committed to the protection of customer information. We will take reasonable steps to ensure that your data is treated securely and in accordance with this Privacy Policy.</p>
        <p>When you sign into your Parlay Poker account or enter payment information, we or our third-party payment processor use secure socket layer technology (&quot;SSL&quot;) to encrypt the transmission of that information. While we take reasonable precautions against possible security breaches of our Services and our customer databases and records, no website or Internet transmission is completely secure, and Parlay Poker cannot guarantee that unauthorized access, hacking, data loss, or other breaches will never occur and that third parties will never be able to overcome those measures or use your personal information for improper purposes.</p>
        <p>Also please note that email and messaging systems are not considered secure, so Parlay Poker discourages you from sending Parlay Poker personal information through these media. We urge you to take steps to keep your personal information safe (including your account password), and to log out of your account after use. If you access our Services through a social network and your social network account is hacked, this may lead to unauthorized play of your Parlay Poker games without your permission. So, be careful to keep your Account Information secure.</p>
        <p>Although we strive to protect your personal data, we cannot guarantee the security of your data while it is being transmitted to our site; any transmission is at your own risk. Once we have received your information, we have procedures and security features in place to try to prevent unauthorized access. If you have questions about the security of our websites, please submit a ticket via the applicable support channel of the game.</p>
        <p><strong>5.b &nbsp; &nbsp;Safety, Security and Compliance with Law</strong></p>
        <p>Your information, and the contents of all your online communications in our Services and between You and Parlay Poker may be accessed and monitored as needed to provide our Service and may be disclosed:</p>
        <p>when we have a good faith belief that we have to disclose the information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements, or legal process (for example, a court order, search warrant or subpoena);</p>
        <p>to satisfy any laws or regulations that apply;</p>
        <p>where we believe in our sole discretion that the Service is being used in committing a crime, including to report such criminal activity or to share information with other companies and organizations for the purposes of fraud protection, credit risk reduction, and other security precautions;</p>
        <p>when we have a good faith belief that there is an emergency that poses a threat to the health and/or safety of you, another person, or the public generally; and</p>
        <p>to protect the rights or property of Parlay Poker and other applicable third parties, including to enforce our Terms of Service.</p>
        <p><strong>5.c &nbsp; &nbsp; International Operations and Transfers Out of the EEA</strong></p>
        <p>When you use Our Services, your data may be sent to the United States and possibly other countries.</p>
        <p>As part of our international operations, we may transfer data to our Affiliates (which means subsidiaries, parent companies, joint ventures, and other corporate entities under Parlay Poker group from time to time for our legitimate business purposes</p>
        <p>We store information about players and visitors to our sites on servers located in the United States, and we may also store this information on servers and equipment in other countries. Personal data collected within the European Economic Area (&ldquo;EEA&rdquo;) may be transferred to, and stored at, a destination outside of the EEA.</p>
        <p>The data we hold may also be processed by employees operating outside of the EEA who work for us or one of our Affiliates or for one of our vendors. This staff may be engaged in, among other things, (i) the processing of transactions and your payment details and (ii) the provision of support services. When we transfer your personal information internationally, we will take reasonable steps to ensure that your personal information is treated securely, lawfully and in the manner we describe here. Please note that laws vary from jurisdiction to jurisdiction, and so the privacy laws applicable to the places where your information is transferred to or stored, used, or processed in, may be different from the privacy laws applicable to the place where you are resident.</p>
        <p>If you are a resident in a jurisdiction, where transfer of your personal information to another jurisdiction requires your consent, then you provide us your express and unambiguous consent to such transfer for us to provide you with our services.</p>
        <p><strong>5.d &nbsp; &nbsp; How Long We Keep Your Information</strong></p>
        <p>How long we retain your information depends on why we collected it and how we use it. We will not retain your personal information for longer than is necessary for our business purposes or for legal requirements. For instance, we may retain some information for a few years after you have closed your account with us if this is necessary to meet our legal obligations or to exercise, defend or establish legal rights.</p>
        <p><strong>5.e &nbsp; &nbsp; Changes to Our Privacy Policy</strong></p>
        <p>If we decide to make material changes to our Privacy Policy, we will tell you and other players by placing a notice on Parlaypoker.com or its equivalent in-game, or by sending you a notice to the e-mail address we have on file for you prior to the change becoming effective. We may supplement this process by placing notices on game blogs, social network pages, and/or forums and on other Parlay Poker websites. You should periodically check&nbsp;<a href="http://www.noideasllc.com/">www.Parlaypoker.com</a> and this privacy page for updates.<br><br><br></p>
        <p><strong>&nbsp;</strong></p>
        <p><strong>6. &nbsp; &nbsp;Contact Us</strong></p>
        <p>For any questions, comments or concerns about our Privacy Policy or any other issue please contact Parlay Poker at: <a href="mailto:privacy@noideasllc.com">Privacy@parlaypoker.com</a> or submit a ticket via the applicable support channel of the game. All notices given by you or required from you under this Privacy Policy shall be in writing and addressed to: &nbsp;Neesan Labs LLC., Attn: Legal Department, 6321 West Dempster Street Suite 272 Morton Grove, IL 60053.</p>

      </v-col>
    </v-row>
  </div>
</template>

<script>

  export default {
    name: "Privacy.vue",
    data() {
      return {

      };
    },
    methods: {

    },
  };
</script>

<style lang="scss" scoped>
  .privacy{
  }
</style>
